<template>
    <div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" v-show="loading">
        <span class="opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 50%">
            <svg class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </span>
    </div>
    <div class="" v-if="!loading">
        <modal-cancel-transaction-timer ref="canceltransaction" />

        <Form @submit="onSubmit" :validation-schema="validationRules" v-slot="{ errors }" id="checkoutTableForm">
            <div class="mb-2" v-if="table">

                <router-link v-if="showCheckout"
                    :to="{ name: 'tableservice', params: { location_slug: location_slug, table_id: table.id } }"
                    class="cursor-pointer inline-flex justify-start"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                            fill="currentColor"
                        />
                    </svg>
                    <div class="ml-3">{{ $t('checkout.back_to_order') }}</div>
                </router-link>
            </div>

            <div class="sm:flex text-default"  >
                <div class="sm:w-4/6 sm:pr-10 relative" v-if="showCheckout">
                    <!-- alert -->
                    <div v-if="$route.params.ordered" class="mb-5 rounded-md alert-success p-4">
                        <div class="flex">
                            <div class="ml-3">
                                <div class="text-sm font-medium">
                                    {{ $t('order.table.confirmation.alert') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="showCheckout">
                        <!-- tableservice -->
                        <div class="content-container checkout-box radius-anchor mb-4">
                            <div class="">
                                <div class="flex flex-wrap items-baseline">
                                    <h2 class="text-2xl font-bold text-lg">{{ $t('checkout.payment_for_table') }}:</h2>
                                </div>
                                <div>
                                    <h4 class="mt-2 text-3xl leading-6 text-default mb-4" v-if="table">{{ table.attributes.name }}</h4>
                                    {{ $t('shoppingcart.table_onaccount_description_after_order') }}
                                </div>
                            </div>
                        </div>

                        <div v-if="timer > 0 && table?.attributes?.hourly_rate_item_id" class="mb-4">
                            <strong class="text-red-500">Tijd om bestelling af te ronden: </strong><br />
                            {{ processSeconds(timer) }}
                        </div>

                        <!-- your details -->
                        <div class="content-container radius-anchor personal-info mb-4">
                            <div class="mb-3">
                                <div class="flex flex-wrap items-baseline">
                                    <h2 class="text-2xl font-bold leading-6">
                                        {{ $t('order.personalinformation') }}
                                    </h2>
                                </div>
                            </div>

                            <div>
                                <label for="customer_email" class="block text-xs" :class="{ 'text-red-500': errors.customer_email }"
                                    >E-mailadres *</label
                                >
                                <div class="mt-1">
                                    <Field
                                        type="email"
                                        v-model="form.customer_email"
                                        name="customer_email"
                                        class="lowercase py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent radius-anchor"
                                        :class="{ error: errors.customer_email }"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- paymenttypes -->
                        <paymenttypes
                            :amount="table.attributes.total"
                            :disabled="saving"
                            ref="paymenttypes"
                            v-if="paymenttypesAvailable === true"
                            @selected="setPaymenttype($event)"
                        />
                    </div>

                    <div class="mt-5 mb-10 relative" v-if="showCheckout">
                        <!-- applepay -->
                        <div v-if="paymenttype.attributes?.psp_method === 'applepay'" class="adyenpayment" id="paymenttype_applepay"></div>

                        <!-- default button -->
                        <div v-else>
                            <button
                                :disabled="saving"
                                type="submit"
                                class="w-full inline-flex btn btn-primary-outline justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t('tables.button.payment') }}
                                <svg
                                    v-if="saving"
                                    class="animate-spin ml-3 h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- receipt -->
                <!-- <div class="sm:w-2/6 sm:h-screen sm:fixed sm:top-20 overflow-auto"> -->
                <div class="md:w-2/6 sm:h-screen sm:sticky sm:top-20 overflow-auto">
                    <div class="content-container checkout-box radius-anchor">
                        <div class="font-bold">Bon</div>
                        <div v-if="table">
                            <div class="my-2 pt-0 px-5" v-for="(line, index) in table.relationships.lines" v-bind:key="index">
                                <div class="flex justify-start">
                                    <div class="w-full">
                                        <div class="flex justify-start">
                                            <!-- Quantity -->
                                            <div class="flex rounded-sm justify-start mr-3">
                                                <div class="text-main pr-3 text-xs">{{ parseFloat(line.attributes.quantity) }}x</div>
                                            </div>
                                            <div class="flex justify-between w-full">
                                                <div class="text-left">
                                                    <div class="text-sm leading-5" :class="{ 'ml-4 italic': line.attributes.addon }">
                                                        {{ line.attributes.description }}
                                                    </div>
                                                    <div
                                                        class="italic text-xs my-2"
                                                        v-for="(kitchen_grocery, grocery_index) in line.attributes.kitchen_groceries"
                                                        v-bind:key="grocery_index"
                                                    >
                                                        {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                                    </div>
                                                </div>
                                                <div class="text-sm">{{ $n(line.attributes.total, currency) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-shoppingcarttotals pt-0 p-5">
                                <div class="my-3 border border-b"></div>

                                <div class="flex justify-between font-bold">
                                    <div>Totaal</div>
                                    <div>{{ $n(table.attributes.total, currency) }}</div>
                                </div>

                                <template v-if="hourlyRateConstruct">
                                    <div class="flex justify-between font-bold">
                                        <div>Plaatskosten</div>
                                        <div>{{ rateToPrice(hourlyRateConstruct.totalPrice) }}</div>
                                    </div>

                                    <div class="my-3 border border-b"></div>

                                    <div class="flex justify-between font-bold">
                                        <div>Totaal</div>
                                        <div>{{ rateToPrice(hourlyRateConstruct.totalPrice + table.attributes.total) }}</div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import axios from 'axios';
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';

import * as _api from '../../lib/api';
import * as _log from '../../lib/log';
import * as _state from '../../lib/state';
import * as _table from '../../lib/table';
import * as _notification from '../../lib/notification';
import * as _transaction from '../../lib/transaction';
import * as _payment from '../../lib/payment';
import * as _applicationState from '../../lib/application-state';

// mixins
import CheckoutHelper from '../../mixins/checkout.helper.js';
import ItemHelper from '../../mixins/item.helper.js';
import CheckoutChecksHelper from '../../mixins/checkout-checks.helper.js';
// import CheckoutPaymenttypeHelper from '../../mixins/checkout-paymenttype.helper.js';

import ModalCancelTransactionTimer from '../../components/ModalCancelTransactionTimer';

import Paymenttypes from '../../components/Paymenttypes';

// set the logger
const logger = _log.get('TABLE PAYMENT');

export default {
    name: 'CheckoutTable',
    props: ['location_slug'],
    mixins: [
        CheckoutHelper,
        CheckoutChecksHelper,
        ItemHelper,
        ModalCancelTransactionTimer,
        // CheckoutPaymenttypeHelper
    ],
    components: {
        Field,
        Form,
        Paymenttypes,
    },
    data() {
        return {
            loading: true,
            saving: false,
            timer: 5 * 60,

            showCheckout: true,

            transactionCancelTimer: null,

            // paymenttype_data: {},

            validationRules: yup.object().shape({
                customer_email: yup.string().required().email().max(255),
            }),
            form: {},
            paymenttype: false,

            hourlyRateConstruct: null,
        };
    },
    async mounted() {
        if (this.table?.attributes?.hourly_rate_item_id) {
            let timerObject = await _table.getBasketTimeData();

            this.timer = timerObject.timer;

            // set transaction timer
            this.setTimer();
        // console.log(this.table.relationships.area);

            // construct new data for hourly rate payment
            this.hourlyRateConstruct = await _table.constructHourlyRate(_applicationState.getHoursDifference());

            // set hours difference to localstorage if not set
            if (!_applicationState.getHoursDifference()) {
                _applicationState.setHoursDifference(this.hourlyRateConstruct.hoursDifference);
            }

             if (!this.table.attributes.hourly_rate_payment_in_webshop) {
                this.showCheckout = false;
             }
        }

        if (this.table?.attributes?.customer_info) {
            this.setCustomerInfo(this.table.attributes.customer_info);
        } else {
            this.form = {
                paymenttype_id: '',
                customer_email: this.user ? this.user.username : '',
            };
        }

        window.checkout = this;

        // to where to set
        axios.defaults.headers.common['Location'] = this.location_slug;

        // load all data
        await this.initilize();
    },

    methods: {
        async initilize() {
            await _table.refresh();

            // select first paymentmethod
            // this.paymenttypeSelect(this.paymenttypes[0]);

            this.loading = false;
        },

        processSeconds(seconds) {
            let minutes = Math.floor(seconds / 60);

            return ('0' + minutes).slice(-3) + ':' + ('0' + (seconds % 60)).slice(-2);
        },

        setTimer() {
            if (this.timer > 0) {
                this.timer--;
                setTimeout(this.setTimer, 1000);
            } else if (this.timer == 0) {
                this.cancelTransaction();
            }
        },

        async cancelTransaction() {
            // reset basket_start_time
            _applicationState.setBasketStartTime(null);
            _applicationState.setHoursDifference(null);

            // back to table
            this.$router.push({
                name: 'tableservice',
                params: {
                    location_slug: this.location_slug,
                },
            });
        },

        setCustomerInfo(data) {
            let info = JSON.parse(data);

            let self = this;
            Object.keys(info).forEach(function (elem) {
                self.form[elem] = info[elem];
            });
        },

        async onSubmit(data) {
            this.saving = true;

            if ((await this.canSubmit()) === false) {
                this.saving = false;
                return;
            }

            // store transaction
            logger.log('store transaction', data)();

            // create the transaction
            await this.createTransaction();

            setTimeout(function () {
                window.checkout.saving = false;
                // window.checkout.$refs.paymenttypes.clearPaymenttype();
            }, 1000);
        },

        async createTransaction() {
            // _transaction.setAccount({
            //     id: this.form.account_id,
            // })

            _transaction.setCustomer({
                email: this.form.customer_email,
            });

            if ((await _transaction.sendToTablePayment(this.hourlyRateConstruct?.hoursDifference)) == false) {
                _notification.set(this.$t('notification.transaction.error'), 'error');
                return;
            }

            logger.log('success', this.transaction)();

            this.makePayment();
        },

        async makePayment() {
            // make payment

            console.log('amount', this.amount);

            const response = await _payment.send(this.amount);

            logger.log('payment response', response)();

            if (response && response.result != 'success') {
                // todo: translate
                _notification.set(this.$t('notification.payment.error'), 'error');
                return;
            }

            _applicationState.setHoursDifference(null);
            _applicationState.setBasketStartTime(null);

            _payment.handleResultCode(this.location_slug, response.data);
        },

        setPaymenttype(paymenttype) {
            this.paymenttype = paymenttype;
            this.form.paymenttype_id = paymenttype.id;
        },

        rateToPrice (rate) {
            return '€ ' +  parseFloat(rate).toFixed(2);
        },
    },

    computed: {
        table() {
            return _table.get();
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        user() {
            return _state.get('user/getUser');
        },

        paymenttypesAvailable() {
            return this.amount > 0;
        },

        amount() {
            if (this.table?.attributes.hourly_rate_item_id) {
                return Number.parseFloat(parseFloat(this.table.attributes.total) + parseFloat(this.hourlyRateConstruct.totalPrice)).toFixed(2);
            }

            return this.table?.attributes?.total;
        },

        config() {
            return _state.get('config/getConfig');
        },

        currency() {
            return this.config.settings.currency;
        },
    },
};
</script>
